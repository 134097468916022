<template>
	<section :class="{
			'mobile': mobileMode,
			'desktop': !mobileMode,
			'standalone': true,
		}"
		class="error-page"
	>
		<header class="page-header">
			<h1 class="page-title">404</h1>
		</header>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import subpageMixin from './subpage-mixin';


export default {
	mixins: [subpageMixin],
};
</script>

<style lang="sass" scoped>
.error-page
	@extend %page !optional
	&.mobile
		@extend %page-mobile

		&.standalone
			@extend %page-mobile-standalone

	&.desktop
		@extend %page-desktop

		&.standalone
			@extend %page-desktop-standalone

.page-header
	@extend %page-header !optional

	.mobile &
		@extend %page-header-mobile

	.mobile.standalone &
		@extend %page-header-mobile-standalone

	.desktop &
		@extend %page-header-desktop

	.desktop.standalone &
		@extend %page-header-desktop-standalone

.page-title
	@extend %page-title

	.mobile &
		@extend %page-title-mobile

	.desktop &
		@extend %page-title-desktop
</style>
